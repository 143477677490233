.sider {
  border-right: 1px solid var(--color-gray-30);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-container {
  padding: 24px 22px 0px 24px;
  transition: padding 0.2s;
}

.header-container.collapsed {
  padding: 24px 14px 0px 14px;
}

.container {
  padding: 0 14px;
}

.container.is-profile {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container.collapsed {
  padding: 0 14px;
}

.select-container {
  margin: 10px 0px;
}

.collapse-icon {
  transition: transform 0.2s;
}

.logo {
  opacity: 1;
  transition: opacity 0.2s;
}

.logo.collapsed {
  opacity: 0;
}

.collapse-icon.collapsed {
  transform: rotate(180deg);
}

.back-icon {
  transform: rotate(90deg);
}


.container button.back-button {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  font-weight: 600;
  margin: 10px 0px;
  color: var(--color-gray-80);
  height: 38px;
}

.logout-button-container {
  margin-top: auto;
}

.logout-button-container .logout-button {
  color: var(--color-gray-80);
  font-weight: 600;
  margin-bottom: 20px;
}