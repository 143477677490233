

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.graph {
  margin-top: 22px;
}

.grid-item.error {
  grid-column: span 3;
}

@media only screen and (max-width: 1400px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-item.error {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 1100px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-item.error {
    grid-column: span 1;
  }
}

@media only screen and (max-width: 800px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
