.container {
  margin-top: 20px;
}

.platform {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  padding: 16px 20px 20px;
  min-width: 694px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: auto;
}

.left, .right {
  display: flex;
  gap: 10px;
  height: 40px;
}

.center {
  height: 40px;
  width: 100%;
  background-color: var(--color-gray-10);
  margin: 12px 0px;
  border-radius: 4px;
}

.pallets {
  width: 100%;
}

.stations {
  width: 100%;
  display: flex;
  gap: 12px;
  position: relative;
}

.pallets-head {
  background-color: var(--color-gray-10);
  min-width: 40px;
  border-radius: 4px;
}

.platform-warning-container {
  border-bottom: 1px solid var(--color-gray-30);
  background-color: var(--color-yellow-10);
  height: 40px;
  position: absolute;
  width: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  left: 0px;
  z-index: 1;
  padding: 10px 12px;
}

.header {
  display: flex;
  justify-content: stretch;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  margin-bottom: 24px;
  padding: 16px;
}

.header-item-container {
  display: flex;
  gap: 12px;
  align-items: center;
  flex: 1;
}

.header-item-container:not(:last-child) {
  border-right: 1px solid var(--color-gray-30);
}

:global(.rtl) .header-item-container:not(:last-child) {
  border-left: 1px solid var(--color-gray-30);
  border-right: none;
}

.header-item-container:not(:first-child) {
  padding-left: 16px;
}

:global(.rtl) .header-item-container {
  padding-right: 16px;
}

.header-icon-container {
  background-color: var(--color-green-10);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius);
}

.header-item-content-value {
  display: flex;
  align-items: baseline;
}

.light {
  color: var(--color-gray-60);
}