.container {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-30);
  background: var(--color-white);
  box-shadow: 0px 1px 2px -5px rgba(10, 9, 11, 0.03), 0px 1px 2px 0px rgba(10, 9, 11, 0.02);
  padding: 16px 20px;
  display: flex;
  gap: 20px;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.item {
  display: flex;
  align-items: center;
  gap: 2px;
}

.content .item:last-child .line {
  display: none;
}

.line {
  height: 16px;
  width: 1px;
  background-color: var(--color-gray-60);
  margin: 0px 12px;
}