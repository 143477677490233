.label {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  padding: 9px 10px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.2s;
  height: 38px;
  --stroke: var(--color-gray);
}

.label.active {
  border-color: var(--color-primary-80);
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  transition: transform 0.2s, top 0.2s;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
}

:global(.rtl) .dropdown-icon {
  right: auto;
  left: 10px;
}

.dropdown-icon.rotated {
  transform: rotate(180deg);
}

.dropdown-icon.collapsed {
  border: 1px solid var(--color-gray-30);
  border-radius: 50%;
  top: 28px;
  right: -8px;
}

.text {
  padding: 0px 22px 0px 12px;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-md);
  line-height: var(--line-height-md);
  color: var(--color-gray);
  opacity: 1;
  transition: opacity 0.2s;
  transition-delay: 0.2s;
}

:global(.rtl) .text {
  padding: 0px 12px 0px 22px;
}

.icon svg {
  width: 100%;
}

.icon.collapsed {
  min-width: 100%;
}

.text.collapsed {
  opacity: 0;
  visibility: hidden;
}

.header {
  padding: 16px;
}

.title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-md);
  line-height: var(--line-height-md);
  color: var(--color-gray);
  margin-bottom: 10px;
}

.menu:global(.ant-dropdown-menu) {
  padding: 0px;
  width: 190px;
}

.menu:global(.ant-dropdown-menu .ant-dropdown-menu-item) {
  border-radius: 0px;
  color: var(--color-gray-90);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu:global(.ant-dropdown-menu .ant-dropdown-menu-submenu) {
  width: 100%;
}

.menu:global(.ant-dropdown-menu .ant-dropdown-menu-item:hover) {
  background-color: var(--color-gray-10);
}

.menu:global(.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected) {
  background-color: var(--color-primary-10);
  color: var(--color-gray);
}

.item {
  flex-direction: row-reverse;
  gap: 12px;
  width: 150px;
  --stroke: var(--color-gray-70);
}

.item:hover {
  --stroke: var(--color-gray);
}

.language-title {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.item :global(.ant-dropdown-menu-submenu-expand-icon) {
  position: absolute;
  top: 10px;
}
