.container {
  width: 100%;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-red-20);
  background: rgba(220, 38, 38, 0.02);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
}

.text {
  max-width: 180px;
  text-align: center;
}